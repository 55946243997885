import React, { useState } from "react";
import chroma from "chroma-js";
import styled from "styled-components";

const LENGTH = 9;

const ColorBlock = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 2.6vw;
  height: 2vh;
  display: inline-block;
`;

const ColorTag = styled.p<{ direction: "left" | "right" }>`
  float: ${(props) => props.direction};
  font-size: 0.5vw;
`;

interface ColorBarProps {
  isFact: boolean;
}

const ColorBar: React.FC<ColorBarProps> = ({ isFact }) => {
  const [colors, setColors] = useState<chroma.Color[]>([]);

  React.useEffect(() => {
    const getColorFromScore = (score: number) => {
      return isFact
        ? chroma.scale(["white", "74b9ff"]).domain([0, LENGTH])(score)
        : chroma.scale(["white", "fa8c8e"]).domain([0, LENGTH])(score);
    };

    let colorArray: chroma.Color[] = [];
    for (let l = 0; l <= LENGTH; l++) {
      colorArray.push(getColorFromScore(l));
    }

    setColors(colorArray);
  }, [isFact]);

  return (
    <div>
      <div id="color-bar">
        Text that influenced the summarization algorithm
        <ColorTag direction="left">weak</ColorTag>
        <ColorTag direction="right">strong</ColorTag>
      </div>
      {colors.map((color, idx) => (
        <ColorBlock color={color.hex()} key={idx} />
      ))}
    </div>
  );
};

export default ColorBar;
