import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { isMobile } from "react-device-detect";

interface InstructionProps {
  nextPage: () => void;
  consumed: boolean;
}

const Instruction: React.FC<InstructionProps> = ({ nextPage, consumed }) => {
  const [formData, setFormData] = useState({
    confirmed: false
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return isMobile ? (
    <div id="end">This experiment cannot be performed on a mobile device.</div>
  ) : consumed ? (
    <div id="end">
      The token has been used before, thank you for participating!
    </div>
  ) : (
    <Fragment>
      <Helmet>
        <title>Lawtech Experiment - Instructions</title>
      </Helmet>
      <div id="instructions">
        <h3>Information about experiment</h3>
        <Form id="lawtech-form">
          <Form.Group controlId="consent" id="form_items">
            <div id="agree-button">
              <p>I agree to participate in the study</p>
              <div id="label-no">NO</div>
              <Form.Check
                type="switch"
                name="confirmed"
                checked={formData.confirmed}
                onChange={handleChange}
                required
              />
              <div id="label-yes">YES</div>
            </div>
          </Form.Group>
          <Button
            className={
              formData.confirmed ? "btn-orange btn-lg" : "btn-secondary btn-lg"
            }
            disabled={!formData.confirmed}
            onClick={formData.confirmed ? nextPage : undefined}
          >
            Begin
          </Button>
        </Form>
        <section>
          <ul>
            <li>
              You will be asked to read a few legal documents and
              answer several questions about each one. We expect this task to
              take around 30 minutes.
            </li>
            <li>Please read this form carefully.</li>
            <li>
              Please contact the investigator or the contact person if you have
              any questions.
            </li>
            <p></p>
            <p>
              <i>
                <b>Study title</b>:
              </i>{" "}
              A Study on Legal Task Completion
            </p>
            <p>
              <i>
                <b>Principal Investigator’s Name and First Name</b>: Aileen
                Nielsen, Fellow in Law &amp; Tech
              </i>
              ,{" "}
              <a href="mailto:aileen.nielsen@gess.ethz.ch">
                aileen.nielsen@gess.ethz.ch
              </a>
            </p>
            <p>
              <i>
                <b>Participant</b>:
              </i>
            </p>
            <li>
              I am using a computer/laptop, not a cell phone/tablet for this
              experiment.
            </li>
            <li>I will not close the browser during the entire experiment.</li>
            <li>
              My responses to questions in this study may be used, on an
              anonymous basis, as content to be used in subsequent studies.
              Specifically, any non-identifying ratings, multiple choice
              responses, or freeform text I produce in this study may be
              displayed as content in a subsequent study on the same topic.
            </li>
            <li>
              I participate in this study on a voluntary basis and can withdraw
              from the study at any time without giving reasons and without any
              negative consequences.
            </li>
            <li>
              I have been informed in writing about the aims and the procedures
              of the study, the advantages and disadvantages, as well as
              potential risks.
            </li>
            <li>
              I had access to written information for research participants.{" "}
              <a
                href="https://docs.google.com/document/d/196hBcwcU48N-Fi8xg-wy5gNJb26EG-aZyqkLyb8a9TY/edit"
                target="_blank"
                rel="noreferrer"
              >
                Link to information sheet.
              </a>{" "}
              Any questions I have related to participating in the study have
              been answered satisfactorily.
            </li>
            <li>
              I was given sufficient time to make a decision about participating
              in the study.
            </li>
            <li>
              By checking “<b>I agree to participate in the study</b>” below, I
              certify that I fulfill the requirements for participating in the
              study stated in the information for the volunteers: that I am at
              least 18 years old, and an English speaker.
            </li>
            <li>
              I have been informed that any possible damage to my health, which
              are directly related to the study and are demonstrably the fault
              of ETH Zurich, are covered by the general liability insurance of
              ETH Zurich (insurance policy no. 30/4.078.362 of the Basler
              Versicherung AG). However, beyond the before mentioned, my health
              and/or accident insurance (e.g. for the commute to or from the
              study location) will be applicable.
            </li>
            <li>
              I agree that the responsible investigators and/or the members of
              the Ethics Commission have access to the original data under
              strictly observed rules of confidentiality.
            </li>
            <li>
              I am aware that during the study I have to comply with the
              requirements and limitations described in the information for
              volunteers. In the interest of my health, the investigators can,
              without mutual consent, exclude me from the study.
            </li>
          </ul>
        </section>
      </div>
    </Fragment>
  );
};

export default Instruction;
