import chroma from "chroma-js";
import React from "react";

interface HighlightProps {
  content: {
    attention_scores: {
      pages: Array<{
        rows: Array<{
          words: Array<{ w: string; s: number }>;
        }>;
      }>;
    };
  };
  complaint: string;
  isFact: boolean;
  isHighlight: boolean;
  isTu: boolean;
}

const Highlight: React.FC<HighlightProps> = ({
  content,
  complaint,
  isFact,
  isHighlight,
  isTu
}) => {
  const maxS = Math.sqrt(
    content.attention_scores.pages.reduce(
      (max, page) =>
        Math.max(
          max,
          page.rows.reduce(
            (maxRow, row) =>
              Math.max(
                maxRow,
                row.words.reduce(
                  (maxWord, word) => Math.max(maxWord, Math.sqrt(word.s)),
                  0
                )
              ),
            0
          )
        ),
      0
    )
  );

  const getColorFromScore = (score: number) => {
    return !isHighlight
      ? "#ffffff"
      : isFact
      ? chroma
          .scale(["#ffffff", "#74b9ff"])
          .domain([0, 1])(Math.sqrt(score) / maxS)
          .hex()
      : chroma
          .scale(["#ffffff", "#fa8c8e"])
          .domain([0, 1])(Math.sqrt(score) / maxS)
          .hex();
  };

  const ColorWord = ({ w, s }: { w: string; s: number }) => {
    // remove :
    if (w === ":") {
      return null;
    }
    // remove the random numbers
    // (numbers at start of a line or on a line of their one with a "." after them)
    // that don't correspond to line numbers
    if (/^[0-9]+\.$/.test(w)) {
      return null;
    }
    const subwords = w.replace(/\\n/g, "*{^}").split("*{");
    return subwords.map((text, index) => (
      <React.Fragment key={index}>
        {text.includes("^}") && <br />}
        <p
          style={{
            display: "inline",
            backgroundColor: getColorFromScore(s),
            border:
              isTu && Math.sqrt(s) / maxS > 0.1 ? "1px dashed red" : "0px",
            fontSize: "2vh"
          }}
        >
          {" "}
          {text.replace("^}", "")}
        </p>
      </React.Fragment>
    ));
  };

  const pageStruct = complaint.split("==========");
  const numPages = Math.floor(pageStruct.length / 2);
  const removeCoverIndices: number[] = [];

  const coverSheetStrings = [
    "COVER SHEET",
    "Nature Of Suit",
    "Mark The Correct",
    "cv10f-010116",
    "120 Marine",
    "Check One Box Below",
    "Malpractice (200)"
  ];

  for (let pageIndex = 0; pageIndex < numPages; pageIndex++) {
    const pageText = pageStruct[2 * (pageIndex + 1)];
    if (coverSheetStrings.some((str) => pageText.includes(str))) {
      removeCoverIndices.push(pageIndex);
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {content["attention_scores"]["pages"].map((pages, pageNumber) => {
        if (removeCoverIndices.includes(pageNumber)) {
          return null;
        }

        return (
          <div
            className="document-page"
            
            key={pageNumber}
          >
            {pages["rows"].map((rows, rowIndex) => (
              <div key={rowIndex} className="document-line-br">
                <div
                  className="document-line"
                  data-lineid={`${pageNumber} ${rowIndex}`}
                >
                  {rows["words"].map((words, wordIndex) => (
                    <div
                      style={{ display: "inline" }}
                      key={wordIndex}
                      className="document-word"
                    >
                      {ColorWord(words)}
                    </div>
                  ))}
                </div>
                <br></br>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default Highlight;
