import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback
} from "react";
import { Helmet } from "react-helmet";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ColorBar from "./ColorBar";
import Question from "./Question";
import CountDownTimer from "./CountDownTimer";
import Highlight from "./Highlight";
export default function Experiment({
  accessToken,
  docNumber,
  nextPage,
  searched,
  stage,
  isHighlight,
  isSummary,
  documentOrder,
  setTotalQuestions,
  setTotalCorrectAnswers,
  setDocOrderIdentifier
}) {
  const [isAllAnswered, setIsAllAnswered] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [documentIdentifier, setDocumentIdentifier] = useState();
  const [numMCQuestions, setNumMCQuestions] = useState(0); // number of MC questions
  var numCorrectAnswers = 0; // MC questions that are answered correctly
  const [allAnswers, setAllAnswers] = useState([]);
  // stage 6, 7 timed
  // const timeLimits = [10, 20, 30]; // development test
  const timeLimits = [240, 420, 300];
  const [timeLimit, setTimeLimit] = useState(timeLimits[docNumber]);
  const [hideWarning, setHideWarning] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isTimeUp, setIsTimeUp] = useState(false);
  // time
  let timer;
  const [time, setTime] = useState(0);
  function startTimer() {
    timer = setInterval(() => {
      setTime((t) => Number(Number(t + 0.1).toFixed(1)));
    }, 100);
  }
  var topBottom = {
    time: 0,
    top: "",
    bottom: "",
    topId: "",
    bottomId: ""
  };
  var topBottoms = [];

  var initialMetadata = {
    startingTimestamp: 0,
    startingUTC: new Date().toString(),
    endingTimestamp: 0,
    endingUTC: new Date().toString(),
    totalTime: 0,
    numberOfMouseClicks: 0,
    timestampsOfMouseClicks: []
  };

  const [metadata, setMetadata] = useState(initialMetadata);

  function setDoc(doc) {
    const newDocData = {
      complaint: doc.complaint,
      fact: JSON.parse(doc.facts),
      documentIdentifier: doc.documentIdentifier,
      questions: JSON.parse(doc.questions)
    };
    setDocumentIdentifier(doc.documentIdentifier);
    setDocOrderIdentifier(doc.documentIdentifier);
    setNumMCQuestions(newDocData.questions.questions.length - 2);
    const numQuestions = newDocData.questions.questions.length;
    var initialAnswer = [];
    for (var i = 0; i < numQuestions; i++) {
      if (i === numQuestions - 1) {
        initialAnswer.push({
          QuestionID: i,
          type: "FF",
          value: "-1",
          previousValue: "-1",
          beginWriting: -1,
          endWriting: -1
        });
      } else {
        initialAnswer.push({
          QuestionID: i,
          type: "MC",
          value: "-1",
          correctAnswer: "",
          selectCount: 0,
          timestampsOfSelection: []
        });
      }
    }
    setAllAnswers(initialAnswer);
    setCurrentDocument(newDocData);
  }

  const fetchDoc = useCallback(async () => {
    const res = await fetch(
      window.$urlPrefix +
        "/api/LawTech/documents/" +
        `${documentOrder[docNumber]}` +
        "/token/" +
        accessToken
    );
    const data = await res.json();
    setDoc(data);
    setIsLoaded(true);
    setIsTimeUp(false);
    setShowWarning(false);
    setHideWarning(false);
  }, [docNumber, accessToken]);

  useEffect(() => {
    fetchDoc();
    startTimer();
    const interval = setInterval(() => {
      var topLines = Array.from(
        document.querySelectorAll(".document-line")
      ).filter((elem) => elem.getBoundingClientRect()["y"] > 40);
      var topLine = topLines[0];
      var topLineWords = Array.from(
        topLine.querySelectorAll(".document-word > span > p"),
        (elem) => elem.innerText
      );
      var topLineSentence = topLineWords.join("");
      var bottomLines = Array.from(
        document.querySelectorAll(".document-line")
      ).filter(
        (elem) => elem.getBoundingClientRect()["y"] < window.innerHeight - 20
      );
      var bottomLine = bottomLines[bottomLines.length - 1];
      var bottomLineWords = Array.from(
        bottomLine.querySelectorAll(".document-word > span > p"),
        (elem) => elem.innerText
      );
      var bottomLineSentence = bottomLineWords.join("");
      var topId = topLine.getAttribute("data-lineid");
      var bottomId = bottomLine.getAttribute("data-lineid");
      if (topId !== topBottom.topId || bottomId !== topBottom.bottomId) {
        topBottom = {
          time: new Date().toString(),
          top: topLineSentence,
          bottom: bottomLineSentence,
          topId: topId,
          bottomId: bottomId
        };
        topBottoms.push(topBottom);
        window.topBottoms = topBottoms;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(timer);
    };
  }, [docNumber, accessToken]);

  function updateAnswers(answers, questionID, newAnswer, questionJson) {
    let question = answers[questionID];
    if (question["type"] === "MC") {
      question["correctAnswer"] =
        questionJson["answer"] != null ? questionJson["answer"].toString() : "";
      question["selectCount"]++;
      question["timestampsOfSelection"] = [
        ...question["timestampsOfSelection"],
        { time: time, answer: newAnswer }
      ];
    } else if (question["type"] === "FF") {
      if (question["endWriting"] === -1) {
        question["beginWriting"] = time;
        question["endWriting"] = time;
      } else if (question["beginWriting"] !== -1) {
        question["endWriting"] = time;
      }
      question["previousValue"] = question["value"];
    }
    question["value"] = newAnswer;
    // check if every question in the current document is all answered
    for (var i = 0; i < currentDocument.questions.questions.length; i++) {
      if (answers[i]["value"] === "-1") {
        setIsAllAnswered(false);
        break;
      } else {
        setIsAllAnswered(true);
      }
    }
  }
  /**
   * DETECT WINDOW RESIZING
   * The windowSize in the first document includes the initial size and the changes
   * For other documents it only includes the changes
   * Reference: https://stackoverflow.com/questions/2996431/detect-when-a-window-is-resized-using-javascript
   */
  const { windowSize } = useWindowDimensions();
  function useWindowDimensions() {
    const [windowSize, setWindowSize] = useState([
      {
        time: time,
        width: window.innerWidth,
        height: window.innerHeight,
        displayWidth: Math.floor(window.innerWidth * 0.7),
        displayHeight: Math.floor(window.innerHeight * 0.81)
      }
    ]);

    const updateWidthAndHeight = () => {
      let resizeTO;
      if (resizeTO) clearTimeout(this.resizeTO);
      resizeTO = setTimeout(function () {
        setWindowSize([
          ...windowSize,
          {
            time: time,
            width: window.innerWidth,
            height: window.innerHeight,
            displayWidth: Math.floor(window.innerWidth * 0.7),
            displayHeight: Math.floor(window.innerHeight * 0.81)
          }
        ]);
      }, 1000); // after 1s record size, to avoid triggering multiple calls
    };

    useEffect(() => {
      window.addEventListener("resize", updateWidthAndHeight);
      return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    return {
      windowSize
    };
  }
  /**
   * DETECT WINDOW RESIZING
   */

  /**
   * DETECT SCROLLING
   * The scrollPos in the first document includes the initial position and the changes
   * For other documents it only includes the changes
   */
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  const [scrollPos, setScrollPos] = useState([
    {
      doc: documentOrder[docNumber],
      time: time,
      position: prevScrollY.current
    }
  ]);
  const scrollTO = useRef(null);

  const onScroll = (e) => {
    let scrollTO;
    const currentScrollY = e.target.scrollTop;
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false);
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);
    }
    prevScrollY.current = currentScrollY;
    // scrollable is the max px when scroll to the bottom
    // 0.81 comes from 90vh of flex-container * 90% of document. (see _experiment.css)
    const scrollable =
      e.target.scrollHeight - Math.floor(window.innerHeight * 0.81);
    if (scrollTO) clearTimeout(this.scrollTO);
    scrollTO = setTimeout(function () {
      setScrollPos([
        ...scrollPos,
        {
          doc: documentOrder[docNumber],
          time: time,
          position: prevScrollY.current,
          scrollable: scrollable,
          scrollingUp: goingUp
        }
      ]);
    }, 1000); // after 1s record scrolling position, to avoid triggering multiple calls
  };
  /**
   * DETECT SCROLLING
   */

  /* SERVER */
  const addMetadata = async (metadata) => {
    const res = await fetch(
      window.$urlPrefix +
        "/api/LawTech/assignments/metadata/" +
        `${documentOrder[docNumber]}` +
        "/token/" +
        accessToken,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify(JSON.stringify(metadata))
      }
    );
  };

  function updateTimestamp() {
    metadata["endingTimestamp"] = time;
    metadata["endingUTC"] = new Date().toString();
    metadata["totalTime"] = (time - metadata["startingTimestamp"]).toFixed(1);
  }

  function handleMouseClick(e) {
    if (e.target.className === "form-check-label") {
      return;
    }
    metadata["numberOfMouseClicks"]++;
    metadata["timestampsOfMouseClicks"] = [
      ...metadata["timestampsOfMouseClicks"],
      time
    ];
    setMetadata(metadata);
  }

  function updateWindowInfo() {
    metadata["windowSize"] = windowSize.filter(
      (item) =>
        item.time >= metadata.startingTimestamp &&
        item.time <= metadata.endingTimestamp
    );
  }

  function updateNumCorrectAnswer(answers, len) {
    for (var i = 0; i < len; i++) {
      if (answers[i]["value"] === answers[i]["correctAnswer"]) {
        numCorrectAnswers++;
      }
    }
    setTotalQuestions(numMCQuestions);
    setTotalCorrectAnswers(numCorrectAnswers);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    updateTimestamp();
    updateWindowInfo();
    updateNumCorrectAnswer(allAnswers, numMCQuestions);
    setMetadata(metadata);
    const submitedData = {
      documentIdentifier,
      numMCQuestions,
      numCorrectAnswers,
      isHighlight,
      isSummary,
      allAnswers,
      metadata,
      topBottoms: window.topBottoms,
      searched
    };
    addMetadata(submitedData);
    setIsAllAnswered(false);
    setMetadata(initialMetadata);
    setTime(0);
    nextPage();
    setTimeLimit(timeLimits[docNumber + 1]);
    // clear FF question input and scroll to top
    document.getElementsByClassName("questionArea")[0].value = "";
    document.getElementsByClassName("document")[0].scrollTo(0, 0);
    document.getElementsByClassName("questions")[0].scrollTo(0, 0);
  };

  const handleTimeoutSubmit = async () => {
    updateTimestamp();
    updateWindowInfo();
    updateNumCorrectAnswer(allAnswers, numMCQuestions);
    setMetadata(metadata);
    const submitedData = {
      documentIdentifier,
      numMCQuestions,
      numCorrectAnswers,
      isHighlight,
      isSummary,
      allAnswers,
      metadata,
      topBottoms: window.topBottoms,
      searched
    };
    await addMetadata(submitedData);
  };

  return isTimeUp ? (
    <div
      id="end"
      style={{
        textAlign: "center",
        padding: "20px"
      }}
    >
      <h2>
        You ran out of time. You will not be permitted to complete the
        experiment, and you cannot attempt the experiment again.
      </h2>
      <h2>Thanks for participating.</h2>
    </div>
  ) : isLoaded ? (
    <Fragment>
      <Helmet>
        <title>{`Lawtech Experiment - Document ${docNumber}`}</title>
      </Helmet>
      <div className="experiment" onClick={handleMouseClick}>
        <div
          style={{
            zIndex: showWarning && !hideWarning ? "1" : "-1",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.4)"
          }}
        ></div>
        <div
          style={{
            zIndex: showWarning && !hideWarning ? "2" : "-1",
            backgroundColor: "white",
            position: "absolute",
            top: "30%",
            left: "25%",
            width: "50%",
            padding: "20px",
            borderRadius: "5px",
            border: "2px solid black",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
        >
          <h3>Warning</h3>
          <div
            style={{
              fontSize: "3vh",
              height: "20vh",
              margin: "10px",
              overflow: "scroll",
              fontWeight: "bold",
              color: "red"
            }}
          >
            You have 60 seconds remaining to complete this task. Please answer
            all questions and move on to the next task in the next 60 seconds.
          </div>
          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              style={{ alignSelf: "flex-end", marginLeft: "auto" }}
              variant={"orange"}
              onClick={() => {
                setHideWarning(true);
              }}
            >
              Got it!
            </Button>
          </div>
        </div>

        <div className="top-div">
          <div className="title-div">
            {isHighlight && <ColorBar isFact={true} />}
            <p className="document-title">
              Document {docNumber + 1} of {documentOrder.length}
            </p>
          </div>
          {stage === 1 && (
            <p className="doc-identifier">
              Document Identifier: {currentDocument.documentIdentifier}
            </p>
          )}
          {stage === 7 && (
            <CountDownTimer
              timeLimit={timeLimit}
              setShowWarning={setShowWarning}
              setIsTimeUp={setIsTimeUp}
              stage={stage}
              accessToken={accessToken}
              handleTimeoutSubmit={handleTimeoutSubmit}
            />
          )}
        </div>
        <div className="flex-container">
          <div className="left-column">
            <div className="document" onScroll={onScroll}>
              <div
                // grey out the document when time is out
                style={{
                  zIndex: isTimeUp ? "1" : "-1",
                  width: "64vw",
                  height: "100vh",
                  position: "fixed",
                  backgroundColor: "rgba(120, 120, 120, 1)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <h2
                  style={{
                    padding: "30px",
                    color: "white"
                  }}
                >
                  You are out of time. The document text will no longer be
                  available. Please complete any remaining questions as quickly
                  as possible and move on to the next task.
                </h2>
              </div>
              <Highlight
                content={currentDocument.fact}
                complaint={currentDocument.complaint}
                isFact={true}
                isHighlight={isHighlight}
                isTu={false}
              />
            </div>
          </div>
          <Form className="right-column" onSubmit={handleSubmit} key={currentDocument.documentIdentifier}>
            {isSummary && (
              <div
                className="summary"
                style={{
                  borderColor: "#74b9ff",
                  fontSize: "2vh",
                  backgroundColor: isTimeUp ? "rgba(120, 120, 120, 1)" : "white"
                }}
              >
                {!isTimeUp && (
                  <div className="highlight-type">
                    <h5 className="summary-title">
                      {currentDocument.fact["summary"] && "Summary of facts:"}
                    </h5>
                  </div>
                )}
                {!isTimeUp ? currentDocument.fact["summary"] : ""}
              </div>
            )}

            <div className="questions">
              {currentDocument.questions.questions.map((question, index) => (
                <div key={index}>
                  <p style={{ fontSize: "2.1vh", fontWeight: "bold" }}>
                    {index === 0
                      ? "Please answer these factual questions. The answers can be found in the legal document."
                      : index === currentDocument.questions.questions.length - 2
                      ? "Please provide your opinion regarding the merits of plaintiff’s suit."
                      : ""}
                  </p>
                  {index < allAnswers.length && (
                    <Question
                      question={question}
                      onAnswer={(value) => {
                        updateAnswers(allAnswers, index, value, question);
                      }}
                      answer={allAnswers[index]["value"]}
                      // disableAnswered={isTimeUp}
                      disableAnswered={false}
                    />
                  )}
                </div>
              ))}
            </div>
            <div style={{ backgroundColor: "white" }}>
              <Button
                size="lg"
                variant={isAllAnswered ? "orange" : "secondary"}
                disabled={!isAllAnswered}
                type="submit"
              >
                {docNumber === documentOrder.length - 1 ? "Submit" : "Next"}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  ) : (
    <div className="loading">
      <h2>Loading...</h2>
      <p>
        (If there is still no response after a long time, you probably don't
        have a valid access token...)
      </p>
    </div>
  );
}
