import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import Button from "react-bootstrap/Button";
import ColorBar from "./ColorBar";
import Highlight from "./Highlight";
import Question from "./Question";

interface TutorialProps {
  nextPage: () => void;
  stage: number;
  isHighlight: boolean;
  isSummary: boolean;
  accessToken: string;
  documentOrder: number[];
}

const Tutorial: React.FC<TutorialProps> = ({
  nextPage,
  stage,
  isHighlight,
  isSummary,
  accessToken,
  documentOrder
}) => {
  const [tutorialIndex, setTutorialIndex] = useState(0);
  const [currentDocument, setCurrentDocument] = useState<any>(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const setDoc = (doc: any) => {
    const newDocData = {
      complaint: doc.complaint,
      damage: JSON.parse(doc.damages),
      fact: JSON.parse(doc.facts),
      documentIdentifier: doc.documentIdentifier,
      questions: JSON.parse(doc.questions)
    };
    setCurrentDocument(newDocData);
  };

  useEffect(() => {
    async function fetchDoc() {
      const res = await fetch(
        `https://lawtech.ethz.ch/api/LawTech/documents/99/token/${accessToken}`
      );
      const data = await res.json();
      setDoc(data);
      setIsLoaded(true);
    }
    fetchDoc();
  }, [accessToken]);

  const tutorialTextPool = [
    {
      label: "both",
      text: [
        {
          id: "general",
          content:
            "You will be asked to answer questions based on reading a legal text."
        },
        {
          id: "summary",
          content:
            "A machine generated summary of allegations is available here."
        },
        {
          id: "text",
          content:
            "The legal text is available in this window. These are scanned documents, so sometimes the text may be confusing. You may also find some text from the cover sheet, in which case you should keep scanning to get to the decision."
        },
        {
          id: "highlight",
          content:
            "Highlighting indicates informative areas to a machine learning algorithm processing the text for allegations summary."
        },
        { id: "question", content: "Answer the questions here." },
        {
          id: "next",
          content:
            "You can move on to the next document by clicking Next, but only after you complete all questions."
        }
      ]
    },
    {
      label: "onlyhighlight",
      text: [
        {
          id: "general",
          content:
            "You will be asked to answer questions based on reading a legal text."
        },
        {
          id: "text",
          content:
            "The legal text is available in this window. These are scanned documents, so sometimes the text may be confusing. You may also find some text from the cover sheet, in which case you should keep scanning to get to the decision."
        },
        {
          id: "highlight",
          content:
            "Highlighting indicates informative areas to a machine learning algorithm processing the text for allegations summary."
        },
        { id: "question", content: "Answer the questions here." },
        {
          id: "next",
          content:
            "You can move on to the next document by clicking Next, but only after you complete all questions."
        }
      ]
    },
    {
      label: "onlysummary",
      text: [
        {
          id: "general",
          content:
            "You will be asked to answer questions based on reading a legal text."
        },
        {
          id: "summary",
          content:
            "A machine generated summary of allegations is available here."
        },
        {
          id: "text",
          content:
            "The legal text is available in this window. These are scanned documents, so sometimes the text may be confusing. You may also find some text from the cover sheet, in which case you should keep scanning to get to the decision."
        },
        { id: "question", content: "Answer the questions here." },
        {
          id: "next",
          content:
            "You can move on to the next document by clicking Next, but only after you complete all questions."
        }
      ]
    },
    {
      label: "none",
      text: [
        {
          id: "general",
          content:
            "You will be asked to answer questions based on reading a legal text."
        },
        {
          id: "text",
          content:
            "The legal text is available in this window. These are scanned documents, so sometimes the text may be confusing. You may also find some text from the cover sheet, in which case you should keep scanning to get to the decision."
        },
        { id: "question", content: "Answer the questions here." },
        {
          id: "next",
          content:
            "You can move on to the next document by clicking Next, but only after you complete all questions."
        }
      ]
    }
  ];

  let tutorialText;

  if (isHighlight && isSummary) tutorialText = tutorialTextPool[0]["text"];
  else if (isHighlight && !isSummary)
    tutorialText = tutorialTextPool[1]["text"];
  else if (!isHighlight && isSummary)
    tutorialText = tutorialTextPool[2]["text"];
  else tutorialText = tutorialTextPool[3]["text"];

  if (stage === 7) {
    let len = tutorialText.length;
    tutorialText.splice(len - 1, 0, {
      id: "time",
      content:
        "For each of the legal documents, there is a time limit to complete the task. You will likely find that you need to read the document and the questions quite quickly to meet the time limit."
    });
    tutorialText.splice(len, 0, {
      id: "warning",
      content:
        "Failure to answer all questions within the time limit will result in your not being permitted to complete the experiment. In this case, you will not receive any compensation."
    });
  }

  const tutorialLength = tutorialText.length;

  function nextTu() {
    setTutorialIndex(tutorialIndex + 1);
  }

  function previousTu() {
    setTutorialIndex(tutorialIndex - 1);
  }

  return isLoaded ? (
    <Fragment>
      <Helmet>
        <title>Lawtech Experiment - Instructions</title>
      </Helmet>{" "}
      <div>
        <div id="shadow"></div>
        <div id="tutorial-tips">
          <h3>
            Tutorial {tutorialIndex + 1}/{tutorialLength}
          </h3>
          <div
            style={{
              fontSize: "3vh",
              height: "20vh",
              margin: "10px",
              overflow: "scroll",
              fontWeight:
                tutorialText[tutorialIndex]["id"] === "warning"
                  ? "bold"
                  : "normal",
              color:
                tutorialText[tutorialIndex]["id"] === "warning"
                  ? "red"
                  : "black"
            }}
          >
            {tutorialText[tutorialIndex]["content"]}
          </div>
          <div id="previous-button">
            {tutorialIndex > 0 && (
              <Button variant={"outline-dark"} onClick={previousTu}>
                Previous
              </Button>
            )}
            <Button
              style={{ alignSelf: "flex-end", marginLeft: "auto" }}
              variant={
                tutorialText[tutorialIndex]["id"] !== "next" ? "dark" : "orange"
              }
              onClick={
                tutorialText[tutorialIndex]["id"] !== "next" ? nextTu : nextPage
              }
            >
              {tutorialText[tutorialIndex]["id"] !== "next"
                ? "Got it!"
                : "All clear. Start!"}
            </Button>
          </div>
        </div>

        <div className="experiment">
          <div className="top-div">
            <div className="title-div">
              {isHighlight && (
                <div
                  style={{
                    zIndex:
                      tutorialText[tutorialIndex]["id"] === "highlight"
                        ? "2"
                        : "0",
                    position:
                      // tutorialText[tutorialIndex]["id"] === "highlight" &&
                      "relative",
                    border:
                      tutorialText[tutorialIndex]["id"] === "highlight"
                        ? "5px dashed red"
                        : "0px"
                  }}
                >
                  <ColorBar isFact={true} />
                </div>
              )}
              <p className="document-title">
                Document 1 of {documentOrder.length}
              </p>
            </div>
            {stage === 1 && (
              <p className="doc-identifier">Document Identifier: xxxxxxx</p>
            )}
          </div>
          <div className="flex-container">
            <div className="left-column">
              <div
                className="document-tu"
                style={{
                  zIndex:
                    tutorialText[tutorialIndex]["id"] === "text" ? "2" : "0",
                  position:
                    // tutorialText[tutorialIndex]["id"] === "text" &&
                    "relative",
                  border:
                    tutorialText[tutorialIndex]["id"] === "text"
                      ? "10px dashed red"
                      : "0px"
                }}
              >
                <div
                  // grey out the document when time is out
                  style={{
                    zIndex: "1",
                    width: "64vw",
                    height: "100vh",
                    position: "fixed",
                    backgroundColor:
                      tutorialText[tutorialIndex]["id"] === "warning"
                        ? "rgba(120, 120, 120, 1)"
                        : "rgba(120, 120, 120, 0)"
                  }}
                ></div>
                <Highlight
                  content={currentDocument.fact}
                  complaint={currentDocument.complaint}
                  isFact={true}
                  isHighlight={isHighlight}
                  isTu={tutorialText[tutorialIndex]["id"] === "highlight"}
                />
              </div>
            </div>
            <div className="right-column">
              {isSummary && (
                <div>
                  <div
                    className="summary"
                    style={{
                      fontSize: "2vh",
                      borderColor: "#74b9ff",
                      zIndex:
                        tutorialText[tutorialIndex]["id"] === "summary"
                          ? "2"
                          : "0",
                      position:
                        // tutorialText[tutorialIndex]["id"] === "summary" &&
                        "relative",
                      border:
                        tutorialText[tutorialIndex]["id"] === "summary"
                          ? "10px dashed red"
                          : "0px",
                      backgroundColor:
                        tutorialText[tutorialIndex]["id"] === "warning"
                          ? "rgba(120, 120, 120, 1)"
                          : "white"
                    }}
                  >
                    {tutorialText[tutorialIndex]["id"] !== "warning" && (
                      <div className="highlight-type">
                        <h5 className="summary-title">Summary of facts:</h5>
                      </div>
                    )}
                    {tutorialText[tutorialIndex]["id"] !== "warning" && (
                      <p>{currentDocument.fact["summary"]}</p>
                    )}
                  </div>
                </div>
              )}
              <div
                className="questions"
                style={{
                  zIndex:
                    tutorialText[tutorialIndex]["id"] === "question"
                      ? "2"
                      : "0",
                  position:
                    // tutorialText[tutorialIndex]["id"] === "question" &&
                    "relative",
                  border:
                    tutorialText[tutorialIndex]["id"] === "question"
                      ? "10px dashed red"
                      : "0px"
                }}
              >
                {currentDocument.questions.questions.map(
                  (
                    question: {
                      question: string;
                      type: "MC" | "FF";
                      options?: string[] | undefined;
                      minCharCount?: number | undefined;
                    },
                    index: React.Key | null | undefined
                  ) => (
                    <Question
                      disableAnswered={false}
                      question={question}
                      onAnswer={() => {}}
                      answer={"-1"}
                      key={index}
                    />
                  )
                )}
              </div>
              <div style={{ backgroundColor: "white" }}>
                <Button
                  size="lg"
                  variant="orange"
                  style={{
                    zIndex:
                      tutorialText[tutorialIndex]["id"] === "next" ? "2" : "0",
                    position:
                      // tutorialText[tutorialIndex]["id"] === "next" &&
                      "relative",
                    border:
                      tutorialText[tutorialIndex]["id"] === "next"
                        ? "5px dashed red"
                        : "0px"
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  ) : (
    <div className="loading">
      <h2>Loading...</h2>
      <p>
        (If there is still no response after a long time, you probably don't
        have a valid access token...)
      </p>
    </div>
  );
};

export default Tutorial;
