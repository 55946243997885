import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import RangeSlider from "react-bootstrap-range-slider";

interface Player {
  plaintiffName: string;
  performedWell: string;
  easyOrDifficult: string;
  confidenceInSystem: string;
  moreEfficient: string;
  relyOnFeature: string;
  understandUsage: string;
  controlUsage: string;
  highlightsQuestion: string;
  summaryQuestion: string;
}

interface PerformanceProps {
  accessToken: string;
  nextPage: () => void;
  isHighlight: boolean;
  isSummary: boolean;
}

interface LabelsProps {
  value: string;
}

interface CustomRangeSliderProps {
  val: string;
  setVal: (val: string) => void;
}

const Performance: React.FC<PerformanceProps> = ({
  accessToken,
  nextPage,
  isHighlight,
  isSummary
}) => {
  const [validated, setValidated] = useState(false);
  const [answered, setAnswered] = useState(
    isHighlight || isSummary ? false : true
  );
  const [plaintiffName, setPlaintiffName] = useState("");
  const [confidenceInSystem, setConfidenceInSystem] = useState("3");
  const [moreEfficient, setMoreEfficient] = useState("3");
  const [relyOnFeature, setRelyOnFeature] = useState("3");
  const [understandUsage, setUnderstandUsage] = useState("3");
  const [controlUsage, setControlUsage] = useState("3");
  const [easyOrDifficult, setEasyOrDifficult] = useState("3");
  const [performedWell, setPerformedWell] = useState("3");
  const [highlightsQuestion, setHighlightsQuestion] = useState("");
  const [summaryQuestion, setSummaryQuestion] = useState("");
  useEffect(() => {
    async function fetchPlaintiffName() {
      const res = await fetch(
        "https://lawtech.ethz.ch/api/LawTech/players/metadata/token/" +
          accessToken
      );
      const data = await res.json();
      setPlaintiffName(data["plaintiffName"]);
    }
    fetchPlaintiffName();
  }, [accessToken]);

  const addPlayer = async (player: Player) => {
    const res = await fetch(
      "https://lawtech.ethz.ch/api/LawTech/players/metadata/token/" +
        accessToken,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify(JSON.stringify(player))
      }
    );
    nextPage();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const player: Player = {
      plaintiffName,
      performedWell,
      easyOrDifficult,
      confidenceInSystem,
      moreEfficient,
      relyOnFeature,
      understandUsage,
      controlUsage,
      highlightsQuestion,
      summaryQuestion
    };
    addPlayer(player);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Lawtech Experiment - Performance Info</title>
      </Helmet>
      <div id="performance">
        <Form
          id="lawtech-form"
          style={{ alignItems: "center" }}
          validated={validated}
          onSubmit={handleSubmit}
        >
          <p>Please rate your agreement with the following statements:</p>
          <Form.Row>
            <Form.Group as={Col} controlId="performedWell">
              <p id="performance-question">
                I performed well on the tasks in this experiment
              </p>
              <Labels value={performedWell} />
              <CustomRangeSlider
                val={performedWell}
                setVal={setPerformedWell}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="easyOrDifficult">
              <p id="performance-question">
                Overall, how easy or difficult was it to perform this task?
              </p>
              <div id="performace-labels">
                <div id="label">very difficult</div>
                <div id="label">somewhat difficult</div>
                <div id="label">neither difficult nor easy</div>
                <div id="label">somewhat easy</div>
                <div id="label">very easy</div>
              </div>
              <CustomRangeSlider
                val={easyOrDifficult}
                setVal={setEasyOrDifficult}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="confidenceInSystem">
              <p id="performance-question">
                I had confidence in the system features to assist me in task
                completion
              </p>
              <Labels value={confidenceInSystem} />
              <CustomRangeSlider
                val={confidenceInSystem}
                setVal={setConfidenceInSystem}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="moreEfficient">
              <p id="performance-question">
                The system features helped me to complete the task more
                efficiently
              </p>
              <Labels value={moreEfficient} />
              <CustomRangeSlider
                val={moreEfficient}
                setVal={setMoreEfficient}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="relyOnFeature">
              <p id="performance-question">
                I relied on the system features to answer the questions
              </p>
              <Labels value={relyOnFeature} />
              <CustomRangeSlider
                val={relyOnFeature}
                setVal={setRelyOnFeature}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="understandUsage">
              <p id="performance-question">
                I understood how and when to use the system features to complete
                the task
              </p>
              <Labels value={understandUsage} />
              <CustomRangeSlider
                val={understandUsage}
                setVal={setUnderstandUsage}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="controlUsage">
              <p id="performance-question">
                I had control using the system features to complete the task
              </p>
              <Labels value={controlUsage} />
              <CustomRangeSlider val={controlUsage} setVal={setControlUsage} />
            </Form.Group>
          </Form.Row>
          {isHighlight && (
            <Form.Row>
              <Form.Group as={Col} controlId="highlightsQuestion">
                <Form.Label>
                  Any comments on how the text highlights affected how you
                  completed the task?
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="open question, min 10 characters"
                  value={highlightsQuestion}
                  onChange={(e) => {
                    setHighlightsQuestion(e.target.value);
                    if (
                      (isHighlight && highlightsQuestion.length < 10) ||
                      (isSummary && summaryQuestion.length < 10)
                    ) {
                      setAnswered(false);
                    } else {
                      setAnswered(true);
                    }
                  }}
                  minLength={10}
                  required
                />
              </Form.Group>
            </Form.Row>
          )}
          {isSummary && (
            <Form.Row>
              <Form.Group as={Col} controlId="summaryQuestion">
                <Form.Label>
                  Any comments on how the text summary affected how you
                  completed the task?
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="open question, min 10 characters"
                  value={summaryQuestion}
                  onChange={(e) => {
                    setSummaryQuestion(e.target.value);
                    if (
                      (isHighlight && highlightsQuestion.length < 10) ||
                      (isSummary && summaryQuestion.length < 10)
                    ) {
                      setAnswered(false);
                    } else {
                      setAnswered(true);
                    }
                  }}
                  minLength={10}
                  required
                />
              </Form.Group>
            </Form.Row>
          )}
          <Button variant="orange btn-lg" type="submit" disabled={!answered}>
            Next
          </Button>
        </Form>
      </div>
    </Fragment>
  );
};

const Labels: React.FC<LabelsProps> = ({ value }) => {
  return (
    <div id="performace-labels">
      <div id="label" style={{ color: value === "1" ? "blue" : "black" }}>
        strongly agree
      </div>
      <div id="label" style={{ color: value === "2" ? "blue" : "black" }}>
        agree
      </div>
      <div id="label" style={{ color: value === "3" ? "blue" : "black" }}>
        neutral
      </div>
      <div id="label" style={{ color: value === "4" ? "blue" : "black" }}>
        disagree
      </div>
      <div id="label" style={{ color: value === "5" ? "blue" : "black" }}>
        strongly disagree
      </div>
    </div>
  );
};

const CustomRangeSlider: React.FC<CustomRangeSliderProps> = ({
  val,
  setVal
}) => {
  return (
    <RangeSlider
      id="range-slide"
      value={val}
      onChange={(e) => setVal(e.target.value)}
      min={1}
      max={5}
      step={1}
      tooltip={"off"}
    />
  );
};

export default Performance;
