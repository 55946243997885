import React, { useState, useEffect } from "react";
import "./styles/App.css";
import Instruction from "./components/Instruction";
import Tutorial from "./components/Tutorial";
import Experiment from "./components/Experiment";
import Performance from "./components/Performance";
import Demographic from "./components/Demographic";
import End from "./components/End";
import Stage1 from "./components/Stage1";

function App() {
  const [stage, setStage] = useState();
  const [isHighlight, setIsHighlight] = useState();
  const [isSummary, setIsSummary] = useState();
  const accessToken = new URL(window.location.href).searchParams.get(
    "accessToken"
  );
  const [page, setPage] = useState(0);
  const [consumed, setConsumed] = useState(false);
  const [documentOrder, setDocumentOrder] = useState([]);
  const [docOrderIdentifier, setDocOrderIdentifier] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [dataStage, isConsumed, randomizationId, dataDocumentOrder] =
        await Promise.all([
          fetch(
            `${window.$urlPrefix}/api/LawTech/players/stage/token/${accessToken}`
          ).then((res) => res.json()),
          fetch(
            `${window.$urlPrefix}/api/LawTech/players/consume/token/${accessToken}`
          ).then((res) => res.json()),
          fetch(
            `${window.$urlPrefix}/api/LawTech/players/randomize/token/${accessToken}`,
            {
              method: "POST",
              body: JSON.stringify({ title: "get randomized ide" }),
              headers: {
                "Content-Type": "application/json"
              }
            }
          ).then((res) => res.json()),
          fetch(
            `${window.$urlPrefix}/api/LawTech/documents/orders/token/${accessToken}`
          ).then((res) => res.json())
        ]);
      setStage(dataStage);
      setConsumed(isConsumed);

      switch (dataStage) {
        case 3:
          setDocumentOrder(
            randomizationId % 8 < 4 ? dataDocumentOrder[2] : dataDocumentOrder[3]
          );
          break;
        case 4:
          setDocumentOrder(
            // randomizationId % 8 < 4 ? dataDocumentOrder[0] : dataDocumentOrder[1]
            dataDocumentOrder[0]
          );
          break;
        case 5:
          setDocumentOrder([
            randomizationId % 8 < 4 ? dataDocumentOrder[0][0] : dataDocumentOrder[1][0]
          ]); // only keep the first document
          break;
        case 6:
        case 7:
          if (randomizationId % 24 < 4) {
            setDocumentOrder(dataDocumentOrder[0]);
          } else if (randomizationId % 24 < 8) {
            setDocumentOrder(dataDocumentOrder[1]);
          } else if (randomizationId % 24 < 12) {
            setDocumentOrder(dataDocumentOrder[2]);
          } else if (randomizationId % 24 < 16) {
            setDocumentOrder(dataDocumentOrder[3]);
          } else if (randomizationId % 24 < 20) {
            setDocumentOrder(dataDocumentOrder[4]);
          } else {
            setDocumentOrder(dataDocumentOrder[5]);
          }
          break;
        default:
          break;
      }

      switch (randomizationId % 4) {
        case 0:
          setIsHighlight(false);
          setIsSummary(false);
          break;
        case 1:
          setIsHighlight(false);
          setIsSummary(true);
          break;
        case 2:
          setIsHighlight(true);
          setIsSummary(false);
          break;
        case 3:
          setIsHighlight(true);
          setIsSummary(true);
          break;
        default:
          break;
      }
    };
    fetchData();
  }, [accessToken]);

  // metadata collection
  const [searched, setSearched] = useState(""); // detect ctrl + F and record search result
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);
  // const [player, setPlayer] = useState(""); // player demographic info

  function nextPage() {
    setPage(page + 1);
  }

  /**
   * DETECT CTRL + F AND RECORD SEARCHED ITEM
   * Reference: https://www.milanlaslop.dev/post/2020-01-11-javascript-detecting-what-the-user-searches-on-the-page/
   */
  function clearElement(element) {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  function prepareSearchDetector() {
    var div = document.createElement("DIV");
    div.setAttribute("id", "search_detector");
    div.setAttribute(
      "style",
      "overflow-y: scroll; height: 1px; position: absolute; left: -100px; top: -100px; margin: 0px; padding: 0px; border: 0; box-sizing: content-box; display: block;"
    );
    document.body.insertBefore(div, document.body.firstChild);
  }

  function addDivWithPossibleString(parent, str) {
    var div = document.createElement("DIV");
    div.setAttribute(
      "style",
      "height: 10px; margin: 0px; padding: 0px; border: 0; box-sizing: content-box; display: block; font: initial; font-size: 8px"
    );
    var text = document.createTextNode(str);
    div.appendChild(text);
    parent.appendChild(div);
  }

  function updateSearchDetector(prefix) {
    var searchDetector = document.getElementById("search_detector");
    clearElement(searchDetector);
    addDivWithPossibleString(searchDetector, prefix);
    for (var char1 = 32; char1 <= 64; ++char1) {
      addDivWithPossibleString(
        searchDetector,
        prefix + String.fromCharCode(char1)
      );
    }
    for (var char2 = 91; char2 <= 126; ++char2) {
      addDivWithPossibleString(
        searchDetector,
        prefix + String.fromCharCode(char2)
      );
    }
  }

  function prepareScrollHandler() {
    var searchDetector = document.getElementById("search_detector");

    searchDetector.onscroll = function (e) {
      var scrollPos = e.target.scrollTop;
      if (scrollPos === 0) {
        return;
      }

      var scrolledToIndex = Math.floor(scrollPos / 10);
      var scrolledToChild = searchDetector.childNodes[scrolledToIndex];
      var searchedFor = scrolledToChild.innerText;

      updateSearchDetector(searchedFor);

      clearElement(searched);
      setSearched([...searched, searchedFor]);
      searchDetector.scrollTop = 0;
    };
  }

  window.onload = function () {
    prepareSearchDetector();
    updateSearchDetector("");
    prepareScrollHandler();
  };
  /**
   * DETECT CTRL + F AND RECORD SEARCHED ITEM
   */

  switch (stage) {
    case 1:
      return <Stage1 accessToken={accessToken} isHighlight={isHighlight} />;
    default:
      switch (page) {
        case 0:
          return <Instruction nextPage={nextPage} consumed={consumed} />;
        case 1:
          return (
            <Tutorial
              nextPage={nextPage}
              stage={stage}
              isHighlight={isHighlight}
              isSummary={isSummary}
              accessToken={accessToken}
              documentOrder={documentOrder}
            />
          );
        case documentOrder.length + 2:
          return (
            <Performance
              accessToken={accessToken}
              nextPage={nextPage}
              isHighlight={isHighlight}
              isSummary={isSummary}
            />
          );
        case documentOrder.length + 3:
          return (
            <Demographic
              accessToken={accessToken}
              nextPage={nextPage}
              stage={stage}
            />
          );
        case documentOrder.length + 4:
          return <End stage={stage} accessToken={accessToken} />;

        default:
          return (
            <Experiment
              accessToken={accessToken}
              docNumber={page - 2}
              nextPage={nextPage}
              searched={searched}
              stage={stage}
              isHighlight={isHighlight}
              isSummary={isSummary}
              documentOrder={documentOrder}
              setTotalQuestions={(value) => {
                setTotalQuestions((c) => c + value);
              }}
              setTotalCorrectAnswers={(value) => {
                setTotalCorrectAnswers((c) => c + value);
              }}
              setDocOrderIdentifier={(value) =>
                setDocOrderIdentifier((c) => [...c, value])
              }
            />
          );
      }
  }
}

export default App;
