import { useEffect } from "react";
import { useCountdown } from "usehooks-ts";
import { Container, Progress } from "reactstrap";
const CountDownTimer = ({ timeLimit, setShowWarning, setIsTimeUp, stage, accessToken, handleTimeoutSubmit}) => {
  const [count, { start, stop, reset }] = useCountdown({
    seconds: timeLimit,
    interval: 1000,
    isIncrement: false
  });
  function showWarning(currentCount) {
    return (
      Math.floor((currentCount % (60 * 60)) / 60) === 0 &&
      Math.floor(currentCount % 60) <= 60
    );
  }
  const minutes = Math.floor((count % (60 * 60)) / 60);
  const seconds = Math.floor(count % 60);
  async function consumeToken() {
    if (stage === 7) {
      const resExitcode = await fetch(
        window.$urlPrefix + "/api/LawTech/players/consume/token/" + accessToken,
        {
          method: "POST",
          body: JSON.stringify({ title: "token consumed" }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then((res) => res.json())
        .then((response) => console.log("Success:", JSON.stringify(response)))
        .catch((error) => console.error("Error:", error));
    }
  }
  useEffect(() => {
    start();
    if (showWarning(count)) setShowWarning(true);
    if (count < 0) {
      handleTimeoutSubmit();
      setIsTimeUp(true);
      consumeToken();
    }
  });
  useEffect(() => {
    reset();
  }, [timeLimit]);
  if (minutes + seconds <= 0) {
    return (
      <div className="expired-notice">
        <p>0:00</p>
      </div>
    );
  } else {
    return (
      <Container className="timer-container">
        <div>
          <Progress
            animated
            value={(count / timeLimit) * 100}
            color={count > 60 ? "success" : "danger"}
          />
          <div className="show-counter">
            <p>
              Time remaining: {minutes.toString()}:
              {seconds.toString().padStart(2, 0)}
            </p>
          </div>
        </div>
      </Container>
    );
  }
};

export default CountDownTimer;
