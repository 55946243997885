import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ColorBar from "./ColorBar";
import Highlight from "./Highlight";

export default function Stage1({ accessToken, isHighlight }) {
  const [isFact, setIsFact] = useState(true);
  const [document, setDocument] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [docNumber, setDocNumber] = useState(0);
  const [search, setSearch] = useState();

  function setDoc(doc) {
    const newDocData = {
      damage: JSON.parse(doc.damages),
      fact: JSON.parse(doc.facts),
      documentIdentifier: doc.documentIdentifier
    };
    setDocument(newDocData);
  }

  useEffect(() => {
    async function fetchDoc() {
      const res = await fetch(
        window.$urlPrefix +
          "/api/LawTech/documents/" +
          `${docNumber + 19}` +
          "/token/" +
          accessToken
      );
      const data = await res.json();
      setDoc(data);
      setIsLoaded(true);
    }
    fetchDoc();
  }, [docNumber, accessToken]);

  function handleKeyPress(target) {
    if (target.charCode === 13) {
      target.preventDefault();
      setDocNumber(search - 1);
    }
  }

  return isLoaded ? (
    <Fragment>
      <Helmet>
        <title>{`Lawtech Experiment - Visualizer`}</title>
      </Helmet>
      <div className="experiment">
        <div className="top-div" style={{ justifyContent: "space-between" }}>
          <ColorBar isFact={isFact} />
          <h2>Document {docNumber + 1} / 58</h2>
          <p className="doc-identifier">
            Document Identifier: {document.documentIdentifier}
          </p>
        </div>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center"
          }}
        >
          <div
            style={{
              justifyContent: "space-between",
              display: "inline-block",
              margin: "10px"
            }}
          >
            <Form style={{ display: "flex", alignItems: "center" }}>
              <Form.Control
                type="number"
                placeholder="Enter doc ID"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={handleKeyPress}
              />
              <Button
                size="lg"
                onClick={(e) => {
                  e.preventDefault();
                  setDocNumber(search - 1);
                }}
              >
                Submit
              </Button>
            </Form>
          </div>
          <div
            style={{
              justifyContent: "space-between",
              display: "inline-block",
              margin: "10px"
            }}
          >
            <Button
              size="lg"
              variant={docNumber > 0 ? "orange" : "secondary"}
              onClick={
                docNumber > 0
                  ? () => {
                      setDocNumber((n) => n - 1);
                    }
                  : () => {}
              }
              style={{ marginRight: "5px", marginTop: "5px" }}
            >
              Previous
            </Button>
            <Button
              size="lg"
              variant={docNumber < 57 ? "orange" : "secondary"}
              onClick={
                docNumber < 57
                  ? () => {
                      setDocNumber((n) => n + 1);
                    }
                  : () => {}
              }
              style={{ marginRight: "5px", marginTop: "5px" }}
            >
              Next
            </Button>
          </div>
          <div
            style={{
              justifyContent: "space-between",
              display: "inline-block",
              margin: "10px"
            }}
          >
            <Button
              size="lg"
              variant={isFact ? "dark" : "outline-dark"}
              onClick={() => {
                setIsFact(true);
              }}
              style={{ marginRight: "5px", marginTop: "5px" }}
            >
              Fact
            </Button>
            <Button
              size="lg"
              variant={!isFact ? "dark" : "outline-dark"}
              onClick={() => {
                setIsFact(false);
              }}
              style={{ marginRight: "5px", marginTop: "5px" }}
            >
              Damage
            </Button>
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <h5 style={{ fontWeight: "bold", textAlign: "center" }}>
            {isFact && document.fact["summary"] && "Summary of facts:"}
            {!isFact && document.damage["summary"] && "Summary of damages:"}
          </h5>
          {isFact ? document.fact["summary"] : document.damage["summary"]}
        </div>
        <div className="flex-container">
          <div className="document" style={{ width: "70vw" }}>
            <Highlight
              isTu={false}
              content={isFact ? document.fact : document.damage}
              isFact={isFact}
              isHighlight={isHighlight}
            />
          </div>
        </div>
      </div>
    </Fragment>
  ) : (
    <div className="loading">
      <h2>Loading...</h2>
      <p>
        (If there is still no response after a long time, you probably don't
        have a valid access token...)
      </p>
    </div>
  );
}
