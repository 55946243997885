import React, { Fragment, useReducer, useEffect } from "react";
import { Helmet } from "react-helmet";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Select from "react-select";

interface RaceOption {
  value: string;
  label: string;
}

interface Player {
  performanceData: any;
  age: string;
  gender: string;
  latin: string;
  race: any;
  city: string;
  zipCode: string;
  degree: string;
  graduationYear: string;
  numPracticeYears: string;
  feedback: string;
  experience: string;
  school: string;
  specialization: string;
}

interface DemographicProps {
  accessToken: string;
  nextPage: () => void;
  stage: number;
}

interface FormState {
  formData: Player;
  validated: boolean;
}

const formDataInitialState: Player = {
  performanceData: undefined,
  age: "",
  gender: "",
  latin: "",
  race: {},
  city: "", // not stage3
  zipCode: "", // not stage3
  degree: "", // not stage3
  graduationYear: "", // not stage3
  numPracticeYears: "", // not stage3
  feedback: "", // only stage3
  experience: "", // stage4 / 5 / 6
  school: "", // only stage6
  specialization: "" // only stage6
};

const validatedInitialState = false;

interface Action {
  type: string;
  payload: any;
}

const reducer = (state: FormState, action: Action) => {
  switch (action.type) {
    case "SET_FORM_DATA":
      return { ...state, formData: action.payload };
    case "SET_VALIDATED":
      return { ...state, validated: action.payload };
    default:
      throw new Error();
  }
};

const Demographic: React.FC<DemographicProps> = ({
  accessToken,
  nextPage,
  stage
}) => {
  const [state, dispatch] = useReducer(reducer, {
    formData: formDataInitialState,
    validated: validatedInitialState
  });

  useEffect(() => {
    async function fetchPerformance() {
      const res = await fetch(
        "https://lawtech.ethz.ch/api/LawTech/players/metadata/token/" +
          accessToken
      );
      const data = await res.json();
      dispatch({
        type: "SET_FORM_DATA",
        payload: { ...state.formData, performanceData: data }
      });
    }
    fetchPerformance();
  }, [accessToken]);

  const addPlayer = async (player: Player) => {
    const res = await fetch(
      "https://lawtech.ethz.ch/api/LawTech/players/metadata/token/" +
        accessToken,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify(JSON.stringify(player))
      }
    );
    nextPage();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch({
      type: "SET_FORM_DATA",
      payload: { ...state.formData, [name]: value }
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      dispatch({
        type: "SET_VALIDATED",
        payload: true
      });
      return;
    }
    addPlayer(state.formData);
  };

  const raceOptions: RaceOption[] = [
    {
      value: "American Indian or Alaska Native",
      label: "American Indian or Alaska Native"
    },
    { value: "Asian", label: "Asian" },
    { value: "Black or African American", label: "Black or African American" },
    {
      value: "Native Hawaiian or Other Pacific Islander",
      label: "Native Hawaiian or Other Pacific Islander"
    },
    { value: "White", label: "White" },
    { value: "Prefer not to answer", label: "Prefer not to answer" }
  ];

  return (
    <Fragment>
      <Helmet>
        <title>Lawtech Experiment - Demographic Info</title>
      </Helmet>
      <div id="demographic">
        <Form
          id="lawtech-form"
          validated={state.validated}
          onSubmit={handleSubmit}
        >
          <Form.Row>
            <Form.Group as={Col} controlId="age">
              <Form.Label>Age</Form.Label>
              <Form.Control
                name="age"
                required
                min="18"
                max="100"
                type="number"
                placeholder="Enter age"
                value={state.formData.age}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="gender">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                required
                value={state.formData.gender}
                onChange={handleChange}
              >
                <option value="" hidden>
                  Select
                </option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="Non-binary">Non-binary</option>
                <option value="Prefer not to answer">
                  Prefer not to answer
                </option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="latin">
              <Form.Label>
                Do you identify ethnically as any of Hispanic, Latino, or
                Spanish origin?
              </Form.Label>
              <Form.Control
                name="latin"
                as="select"
                required
                value={state.formData.latin}
                onChange={handleChange}
              >
                <option value="" hidden>
                  Select
                </option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="race">
              <Form.Label>Race</Form.Label>
              <Select
                isMulti
                name="race"
                options={raceOptions}
                placeholder="Select all that apply"
                onChange={(selectedOptions) => {
                  dispatch({
                    type: "SET_FORM_DATA",
                    payload: { ...state.formData, race: { selectedOptions } }
                  });
                }}
              />
            </Form.Group>
          </Form.Row>

          {stage !== 3 && (
            <Form.Row>
              <Form.Group as={Col} controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  name="city"
                  type="text"
                  placeholder="City"
                  required
                  value={state.formData.city}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="zipCode">
                <Form.Label>Zip</Form.Label>
                <Form.Control
                  name="zipCode"
                  type="text"
                  placeholder="Zip"
                  required
                  pattern="\d{4,5}"
                  value={state.formData.zipCode}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>
          )}

          {stage !== 3 && stage !== 6 && (
            <Form.Row>
              <Form.Group as={Col} controlId="degree">
                <Form.Label>Student Status</Form.Label>
                <Form.Control
                  name="degree"
                  as="select"
                  required
                  value={state.formData.degree}
                  onChange={handleChange}
                >
                  <option value="" hidden>
                    Select
                  </option>
                  <option value="Degree J.D.">Degree J.D.</option>
                  <option value="Bachelor's (Europe)">
                    Bachelor's (Europe)
                  </option>
                  <option value="Master's">Master's</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="graduationYear">
                <Form.Label>Expected Graduation Year</Form.Label>
                <Form.Control
                  name="graduationYear"
                  required
                  min="2023"
                  max="2025"
                  type="number"
                  placeholder="2023-2025"
                  value={state.formData.graduationYear}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>
          )}
          {stage === 6 && (
            <Form.Row>
              <Form.Group as={Col} controlId="numPracticeYears">
                <Form.Label>Number of years spent practicing law</Form.Label>
                <Form.Control
                  name="numPracticeYears"
                  type="number"
                  placeholder="Enter years"
                  required
                  min="0"
                  max="80"
                  value={state.formData.numPracticeYears}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="specialization">
                <Form.Label>Area of legal specialization</Form.Label>
                <Form.Control
                  name="specialization"
                  as="textarea"
                  placeholder="Enter your specialization here"
                  value={state.formData.specialization}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Form.Row>
          )}
          {stage === 6 && (
            <Form.Row>
              <Form.Group as={Col} controlId="experience">
                <Form.Label>
                  Please provide a link to prove your attorney status. This can
                  be your LinkedIn profile, your law firm profile, or your
                  state's bar registry. This information will not be linked to
                  your experimental data, and all information is kept fully
                  anonymous. This is needed for purposes of compensation to
                  ensure you are an attorney.
                </Form.Label>
                <Form.Control
                  name="experience"
                  as="textarea"
                  placeholder="Enter your experiences here"
                  value={state.formData.experience}
                  onChange={handleChange}
                  minLength={10}
                  required
                />
              </Form.Group>
            </Form.Row>
          )}
          {stage === 6 && (
            <Form.Row>
              <Form.Group as={Col} controlId="school">
                <Form.Label>Where did you attend law school?</Form.Label>
                <Form.Control
                  name="school"
                  as="textarea"
                  placeholder="Enter your school here"
                  value={state.formData.school}
                  onChange={handleChange}
                  minLength={1}
                  required
                />
              </Form.Group>
            </Form.Row>
          )}
          {(stage === 4 || stage === 5) && (
            <Form.Row>
              <Form.Group as={Col} controlId="experience">
                <Form.Label>
                  What did you study in your undergraduate degree? What
                  practical legal experience do you have, if any (e.g. prior
                  work as a paralegal, summer internship etc)?
                </Form.Label>
                <Form.Control
                  name="experience"
                  as="textarea"
                  placeholder="Enter your experiences here"
                  value={state.formData.experience}
                  onChange={handleChange}
                  minLength={10}
                  required
                />
              </Form.Group>
            </Form.Row>
          )}
          {(stage === 3 || stage === 4 || stage === 5) && (
            <Form.Row>
              <Form.Group as={Col} controlId="feedback">
                {stage === 3 ? (
                  <Form.Label>
                    Is there anything you would like to tell us about the
                    experiment? Did you find any of the language confusing? Did
                    everything make sense?
                  </Form.Label>
                ) : (
                  <Form.Label>
                    Anything you wish to share with us about your thoughts on
                    this experiment?
                  </Form.Label>
                )}
                <Form.Control
                  name="feedback"
                  as="textarea"
                  placeholder="Enter your feedbacks here"
                  value={state.formData.feedback}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>
          )}

          <Button variant="orange btn-lg" type="submit">
            Next
          </Button>
        </Form>
      </div>
    </Fragment>
  );
};

export default Demographic;
