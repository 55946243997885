import React, { ChangeEvent, ClipboardEvent } from "react";
import Form from "react-bootstrap/Form";

interface QuestionProps {
  question: {
    question: string;
    type: "MC" | "FF";
    options?: string[];
    minCharCount?: number;
  };
  onAnswer: (value: string | number) => void;
  answer: string | number;
  disableAnswered: boolean;
}

const MultipleChoiceQuestion: React.FC<QuestionProps> = ({
  question,
  onAnswer,
  answer,
  disableAnswered
}) => {
  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    onAnswer(Number(e.target.value));
  };

  return (
    <>
      {question.options?.map((option, index) => (
        <Form.Check key={index}>
          <Form.Check.Input
            disabled={disableAnswered && answer !== -1}
            id={`${question.question}-${index}`}
            value={index}
            type="radio"
            name={question.question}
            onChange={handleOptionChange}
            checked={answer === index}
          />
          <Form.Check.Label
            htmlFor={`${question.question}-${index}`}
            style={{ display: "block" }}
          >
            {option}
          </Form.Check.Label>
        </Form.Check>
      ))}
    </>
  );
};

const FreeFormQuestion: React.FC<QuestionProps> = ({
  question,
  onAnswer,
  answer,
  disableAnswered
}) => {
  const handleFreeFormChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (
      typeof question.minCharCount === "number" &&
      value.length >= question.minCharCount
    ) {
      onAnswer(value);
    } else {
      onAnswer("-1");
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    alert("Don't copy paste!");
  };

  return (
    <Form.Group>
      <Form.Text className="text-muted">
        {`Enter your answer of at least ${question.minCharCount} characters`}
      </Form.Text>
      <Form.Control
        as="textarea"
        className="questionArea"
        name={question.question}
        onChange={handleFreeFormChange}
        onPaste={handlePaste}
      />
    </Form.Group>
  );
};

const Question: React.FC<QuestionProps> = ({
  question,
  onAnswer,
  answer,
  disableAnswered
}) => {
  const renderQuestion =
    question.type === "MC" ? (
      <MultipleChoiceQuestion
        {...{ question, onAnswer, answer, disableAnswered }}
      />
    ) : (
      <FreeFormQuestion {...{ question, onAnswer, answer, disableAnswered }} />
    );

  return (
    <Form.Group style={{ fontSize: "2vh" }}>
      <Form.Label style={{ display: "inline-block" }}>
        <span
          style={{
            height: "15px",
            width: "15px",
            backgroundColor: answer === "-1" ? "#bbb" : "green",
            display: "inline-block",
            marginRight: "10px"
          }}
        />
        {question.question}
      </Form.Label>
      {renderQuestion}
    </Form.Group>
  );
};

export default Question;
