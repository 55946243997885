import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Clipboard from "react-clipboard.js";
import { useAsync } from "react-use";

interface EndProps {
  stage: number;
  accessToken: string;
}

const End: React.FC<EndProps> = ({ stage, accessToken }) => {
  const [exitcode, setExitcode] = useState("");

  const {
    error,
    loading,
    value: dataExitcode
  } = useAsync(async () => {
    if (stage === 3) {
      const resExitcode = await fetch(
        "https://lawtech.ethz.ch" +
          "/api/LawTech/players/exitcode/token/" +
          accessToken
      );
      return await resExitcode.text();
    } else if (stage === 4 || stage === 5 || stage === 6 || stage === 7) {
      const resExitcode = await fetch(
        "https://lawtech.ethz.ch" +
          "/api/LawTech/players/consume/token/" +
          accessToken,
        {
          method: "POST",
          body: JSON.stringify({ title: "token consumed" }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      return resExitcode.json();
    }
  }, [accessToken, stage]);

  useEffect(() => {
    if (dataExitcode && typeof dataExitcode === "string") {
      setExitcode(dataExitcode);
    }
  }, [dataExitcode]);

  return (
    <Fragment>
      <Helmet>
        <title>Lawtech Experiment - End</title>
      </Helmet>
      <div id="end">
        {error && <h3>Something went wrong. Please try again later.</h3>}
        {loading && <h3>Loading...</h3>}
        {(stage === 4 || stage === 5 || stage === 6 || stage === 7) &&
          !loading &&
          !error && (
            <>
              <h3>Thank you for participating!</h3>
              <h3>
                Your information has been recorded and the gift card will be
                sent to you via email within a few days.
              </h3>
            </>
          )}

        {stage === 3 && !loading && !error && (
          <>
            <h3>
              You have finished the study. Thank you for taking the time! In
              order to receive your payment you must copy and paste the
              following code back to Amazon Mechanical Turk:
            </h3>
            <h3>{exitcode}</h3>
            <Clipboard data-clipboard-text={exitcode}>
              copy to clipboard
            </Clipboard>
            <h3>
              Your payment will be processed within the next 24 hours. If you
              encounter problems submitting this HIT, please search for a HIT
              called "ETH Descil Trouble Ticket" and report your problem there.
            </h3>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default End;
